export const formatarValorMonetario = (input: unknown): string => {
  let valor = typeof input === 'string' ? input : String(input);

  if (valor.trim() === '') return '0,00';

  valor = valor.replace(/[^\d.]/g, '');

  const numero = parseFloat(valor);

  if (Number.isNaN(numero)) return '0,00';

  const ajustado = (numero / 100).toFixed(2);
  const valorFormatado = ajustado.replace('.', ',');

  return valorFormatado;
};
