import api from '~/services/api';
import { CadastroCliente, ResponseFormatted } from '../../types';
import {
  FinalizadorasProps,
  InsertClienteResponse,
  ResponseFinalizadoraTecla,
  ResponseGetClienteCpfCnpj,
} from '../../types/services';

const getTeclaFinalizadora = async (
  cod_loja: string | number,
  cod_finalizadora: number,
): Promise<ResponseFinalizadoraTecla> => {
  const { data } = await api.get<ResponseFinalizadoraTecla>(
    `/finalizadora-tecla/${cod_loja}/${cod_finalizadora}`,
  );
  return data;
};

const getFinalizadoraByTecla = async (
  cod_loja: string | number,
  des_tecla: string,
): Promise<ResponseFormatted<FinalizadorasProps>> => {
  const { data } = await api.get<ResponseFormatted<FinalizadorasProps>>(
    `/finalizadora/${cod_loja}/${des_tecla}`,
  );
  return data;
};

const getClienteCpfCnpj = async (
  num_cpf_cnpj: string,
): Promise<ResponseFormatted<ResponseGetClienteCpfCnpj | null>> => {
  const { data } = await api.get<
    ResponseFormatted<ResponseGetClienteCpfCnpj | null>
  >(`/busca-cliente/${num_cpf_cnpj}`);
  return data;
};

const insereCliente = async (
  formData: CadastroCliente,
): Promise<InsertClienteResponse> => {
  const { data } = await api.post<InsertClienteResponse>(
    '/cliente-pdv',
    formData,
  );
  return data;
};

const getCondicaoPagamento = async (
  cod_condicao: number,
): Promise<{ des_condicao: string }> => {
  const { data } = await api.get<{ des_condicao: string }>(
    `/condicao-pagamento/${cod_condicao}`,
  );
  return data;
};

export const tabFinalizacaoServices = {
  getTeclaFinalizadora,
  getFinalizadoraByTecla,
  getClienteCpfCnpj,
  insereCliente,
  getCondicaoPagamento,
};
