import api from '~/services/api';

export const getSaldoCaixa = async (
  cod_operador: number,
  num_pdv: number,
  cod_loja: number,
): Promise<{ saldo: number }> => {
  const { data } = await api.get<{ saldo: number }>(
    `/busca-saldo/${cod_operador}/${num_pdv}/${cod_loja}`,
  );
  return data;
};
