import api from '~/services/api';

export const getEtiquetaBalanca = async (
  cod_loja: number,
  cod_plu: string,
): Promise<number | boolean> => {
  const { data } = await api.get<number | boolean>(
    `/etiqueta-balanca/${cod_loja}/${cod_plu}`,
  );
  return data;
};
