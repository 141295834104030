import * as yup from 'yup';

export const schema = yup.object({
  cod_loja: yup.number().required(),
  num_pdv: yup.number().min(1).max(99999999).required(),
  num_terminal: yup.number().min(0).max(99999999).nullable(true).notRequired(),
  num_tipo_turno: yup
    .object()
    .defined({ value: 0, label: 'COM' })
    .shape({
      label: yup.string().notRequired().nullable(true),
      value: yup.number().notRequired().nullable(true),
    })
    .notRequired(),
});
