import { StatusPedido } from '../types/context';

export const getStatusMessage = (status: StatusPedido): string => {
  switch (status) {
    case 'LANCAMENTO_INCIADO':
      return 'O lançamento do pedido foi iniciado...';
    case 'LANCAMENTO_FINALIZADO':
      return 'O lançamento foi finalizado com sucesso.';
    case 'FALHA_LANCAMENTO':
      return 'Não foi possível lançar o pedido.';
    default:
      return 'Status desconhecido';
  }
};
