import api from '~/services/api';
import { ReceiptData } from '~/utils/classes/ImpressaoRecibo/PdvOnline/receipt.data';
import {
  AberturaAdequadaResponse,
  InsereMovimentoData,
  LojasProps,
  OperadorProps,
  PdvProps,
} from '../../types/services';
import { ResponseFormatted } from '../../types';

const getLojas = async (): Promise<LojasProps[]> => {
  const { data } = await api.get<LojasProps[]>('/busca-loja');
  return data;
};

const getOperador = async (
  cod_loja: string | number,
): Promise<OperadorProps[]> => {
  const { data } = await api.get<OperadorProps[]>(
    `/busca-operador/${cod_loja}`,
  );
  return data;
};

const getCaixa = async (cod_loja: string | number): Promise<PdvProps[]> => {
  const { data } = await api.get<PdvProps[]>(`/busca-caixa/${cod_loja}`);
  return data;
};

const getCertificado = async (cod_loja: string | number): Promise<boolean> => {
  const { data } = await api.get<boolean>(`/busca-certificado/${cod_loja}`);
  return data;
};

const checkCaixaFechado = async (
  num_pdv: string | number,
  cod_loja: string | number,
): Promise<boolean> => {
  const { data } = await api.get<boolean>(
    `/caixa-fechado/${num_pdv}/${cod_loja}`,
  );
  return data;
};

const checkCaixaFechadoAdequadamente = async (
  num_pdv: string | number,
  cod_loja: string | number,
): Promise<AberturaAdequadaResponse> => {
  const { data } = await api.get<AberturaAdequadaResponse>(
    `/caixa-fechado-adequadamente/${num_pdv}/${cod_loja}`,
  );
  return data;
};

const insereMovimento = async (
  num_pdv: string | number,
  cod_loja: string | number,
  formData: InsereMovimentoData,
): Promise<ResponseFormatted<boolean>> => {
  const { data } = await api.post<ResponseFormatted<boolean>>(
    `/movimento/${num_pdv}/${cod_loja}`,
    formData,
  );
  return data;
};

const checkCaixaOutroOperador = async (
  num_pdv: string | number,
  cod_loja: string | number,
  cod_operador: number,
): Promise<boolean> => {
  const { data } = await api.get<boolean>(
    `/caixa-outro-operador/${num_pdv}/${cod_loja}/${cod_operador}`,
  );
  return data;
};

const tesourariaStatus = async (
  num_pdv: string | number,
  cod_loja: string | number,
): Promise<void> => {
  const { data } = await api.put<void>(
    `/tesouraria-status/${num_pdv}/${cod_loja}`,
  );
  return data;
};

const getLojaCupomAbertura = async (
  cod_loja: number,
  cod_operador: number,
  num_pdv: number,
): Promise<ReceiptData | boolean> => {
  const { data } = await api.get<ReceiptData | boolean>(
    `/busca-loja/${cod_loja}/${cod_operador}/${num_pdv}`,
  );
  return data;
};

const checkCaixaMesmoOperador = async (
  num_pdv: string | number,
  cod_loja: string | number,
  cod_operador: number,
): Promise<{ val_saldo: number }> => {
  const { data } = await api.get<{ val_saldo: number }>(
    `/caixa-mesmo-operador/${num_pdv}/${cod_loja}/${cod_operador}`,
  );
  return data;
};

export const aberturaPdv = {
  getLojas,
  getOperador,
  getCaixa,
  getCertificado,
  checkCaixaFechado,
  checkCaixaFechadoAdequadamente,
  insereMovimento,
  checkCaixaOutroOperador,
  tesourariaStatus,
  getLojaCupomAbertura,
  checkCaixaMesmoOperador,
};
