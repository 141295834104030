import api from '~/services/api';
import { BuscaVendaParams, BuscaVendaResponse } from '../types/services';

const buscaVenda = async (
  cod_loja: number,
  params?: BuscaVendaParams,
): Promise<BuscaVendaResponse[]> => {
  const { data } = await api.get<BuscaVendaResponse[]>(
    `/busca-venda/${cod_loja}`,
    { params },
  );
  return data;
};

export const vendasService = { buscaVenda };
