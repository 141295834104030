import api from '~/services/api';
import { LeituraCaixaResponse, ResponseFormatted } from '../types';

export const getLeituraCaixa = async (
  cod_loja: string | number,
  num_pdv: string | number,
  cod_operador: string | number,
): Promise<ResponseFormatted<LeituraCaixaResponse>> => {
  const { data } = await api.get<ResponseFormatted<LeituraCaixaResponse>>(
    `/pdv-online/leitura-de-caixa/${cod_loja}/${num_pdv}/${cod_operador}`,
  );
  return data;
};
