import api from '~/services/api';
import { FinalizadorasSangriaProps } from '../types';

export const getSangriaFinalizadora = async (
  cod_operador: number,
  num_pdv: number,
  cod_loja: number,
): Promise<FinalizadorasSangriaProps[]> => {
  const { data } = await api.get<FinalizadorasSangriaProps[]>(
    `/sangria-finalizadora/${cod_operador}/${num_pdv}/${cod_loja}`,
  );
  return data;
};
