import { GridRowParams } from '@material-ui/data-grid';
import React, { useCallback, useState, useContext, ChangeEvent } from 'react';
import { toast } from 'react-toastify';
import DefaultLoader from '~/components/DefaultLoader';
import FormDefault from '~/components/FormDefault';
import { InputNumber, InputSelect } from '~/components/NovosInputs';
import Loja from '~/components/Loja';
import Search from '~/components/Search';
import ToggleDefault from '~/components/ToggleDefault';
import useAuth from '~/hooks/useAuth';
import api from '~/services/api';
import { LojaContext } from '~/context/loja';

import { Container } from './styles';
import { SelectProps } from './types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validations';

const tiposTurno = [
  {
    label: 'COM',
    value: 0,
  },
  {
    label: 'SEM',
    value: 1,
  },
];

const Pdv: React.FC = () => {
  const { loja } = useContext(LojaContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [flagInativo, setFlagInativo] = useState<boolean>(false);
  const user = useAuth();
  const [lojas, setLojas] = useState<number>(loja.cod_loja);

  const {
    handleSubmit,
    setValue,
    control,
    register,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  /**
   * Reseta form para criação de novo registro
   */
  const formBlank = {
    num_pdv: 0,
    num_terminal: 0,
    cod_loja: { value: null, label: 'Selecione...' },
    num_tipo_turno: 0,
    flg_inativo: false,
  };

  const resetFormData = useCallback(() => {
    setFlagInativo(false);
    reset(formBlank);
    setTimeout(() => {
      setValue('num_tipo_turno', { value: 0, label: 'COM' });
    }, 10);
    setLojas(loja.cod_loja);
    setUpdate(false);
  }, [reset, setValue]);

  /**
   * Passa os dados da tabela para o formulário
   */
  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    setUpdate(true);
    const {
      num_pdv,
      cod_loja,
      des_loja,
      flg_inativo,
      num_tipo_turno,
      num_terminal,
    } = row;
    setLojas(cod_loja);

    setFlagInativo(flg_inativo);
    setValue('cod_loja', {
      value: cod_loja,
      label: `${cod_loja}-${des_loja}`,
    });
    setValue('num_pdv', num_pdv);
    setValue('num_terminal', num_terminal);

    setValue(
      'num_tipo_turno',
      tiposTurno.find((turno) => {
        return turno.value === num_tipo_turno;
      }),
    );

    setLoader(false);
    setShowSearch(false);
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  const onSave = handleSubmit(async (dataForm) => {
    try {
      if (isUpdate) {
        const { data } = await api.put(`/pdvs`, {
          cod_loja: dataForm.cod_loja,
          num_pdv: dataForm.num_pdv,
          num_terminal: dataForm.num_terminal,
          flg_inativo: flagInativo,
          num_tipo_turno: dataForm.num_tipo_turno.value,
        });

        if (data.success) {
          toast.success('PDV atualizado com Sucesso');
          resetFormData();
          setUpdate(false);
          setShowSearch(true);
          return;
        }
      }
      const { data } = await api.post('/pdvs', {
        cod_loja: dataForm.cod_loja,
        num_pdv: dataForm.num_pdv,
        num_terminal: dataForm.num_terminal,
        flg_inativo: flagInativo,
        num_tipo_turno: dataForm.num_tipo_turno.value,
      });
      if (data.success) {
        toast.success('PDV Cadastrado com Sucesso');
        resetFormData();
      }
    } finally {
      setLoader(false);
    }
  });

  /**
   * Inativa um PDV
   */

  if (loader) {
    return <DefaultLoader />;
  }

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={11}
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
        />
      )}
      {!showSearch && (
        <FormDefault
          codTela={11}
          codigoRegistro={[
            {
              value: watch('num_pdv'),
              des_campo: 'Número do PDV',
            },
          ]}
          title="Cadastro de PDV"
          onSave={onSave}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={() => null}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
          isDelete={false}
        >
          <div className="row">
            <div className="col-sm-12 col-md-10" style={{ marginTop: '8px' }}>
              <Loja
                selectedLoja={lojas}
                IsInvalid={false}
                onChange={(e: number) => {
                  setValue('cod_loja', e);
                  setLojas(e);
                }}
                isDisabled={isUpdate}
              />
            </div>
            <div
              className="col-12 d-flex  gap-5 align-items-start fluid"
              style={{ marginTop: '10px' }}
            >
              <div className="col-sm-12 col-md-3">
                <InputNumber
                  label="Número do PDV"
                  name="num_pdv"
                  disabled={isUpdate}
                  placeholder="0"
                  maxLength={99999999}
                  max={99999999}
                  control={control}
                  register={register}
                  isError={!!errors.num_pdv}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (String(e.target.value).length <= 8) {
                      setValue('num_pdv', e.target.value);
                    }
                  }}
                />
              </div>
              <div className="col-sm-6 col-md-2">
                <InputNumber
                  label="Número do Terminal"
                  name="num_terminal"
                  placeholder="0"
                  maxLength={99999999}
                  max={99999999}
                  control={control}
                  register={register}
                  isError={!!errors.num_terminal}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (String(e.target.value).length <= 8) {
                      setValue('num_terminal', Number(e.target.value));
                    }
                  }}
                />
              </div>
              <div className="col-sm-6 col-md-2">
                <InputSelect
                  label="Turno"
                  name="num_tipo_turno"
                  placeholder="COM"
                  control={control}
                  register={register}
                  options={tiposTurno}
                  changeSelected={(selected: SelectProps) => {
                    setValue('num_tipo_turno', selected);
                    clearErrors('num_tipo_turno');
                  }}
                  isError={!!errors.num_tipo_turno}
                />
              </div>
              <div style={{ marginTop: '0.659rem' }}>
                <ToggleDefault
                  labelText="Inativo?"
                  setChecked={flagInativo}
                  onSwitch={() => setFlagInativo(!flagInativo)}
                />
              </div>
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default Pdv;
