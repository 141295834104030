import styled from 'styled-components';

export const MainContainer = styled.section`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const MainHeader = styled.header<{ openingPdv: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: ${(props) => (props.openingPdv ? 1001 : 999)};

  padding-right: 2.5rem;
  gap: 0.625rem;
`;

export const MainContent = styled.div`
  padding: 0 1.75rem;
  flex: 1;
  box-sizing: border-box;
`;

export const Spacer = styled.div`
  flex: 1;
`;
