import { LeituraData } from '../types';

export const calcularTotaisLeitura = (leituras: LeituraData): any[] => {
  const totaisPorFinalizadora: Record<string, any> = {};

  const atualizarTotais = (
    array: any[] | null,
    valorChave: string,
    operacao: 'soma' | 'subtracao',
  ) => {
    array?.forEach(({ cod_finalizadora, des_finalizadora, ...item }) => {
      if (!totaisPorFinalizadora[cod_finalizadora]) {
        totaisPorFinalizadora[cod_finalizadora] = {
          des_finalizadora,
          value: 0,
        };
      }
      const valor = operacao === 'soma' ? item[valorChave] : -item[valorChave];
      totaisPorFinalizadora[cod_finalizadora].value += valor;
    });
  };

  atualizarTotais(leituras.abertura, 'val_abertura', 'soma');
  atualizarTotais(leituras.vendas, 'val_finalizadora', 'soma');
  atualizarTotais(leituras.reforco, 'val_reforco', 'soma');
  atualizarTotais(leituras.sangria, 'val_sangria', 'subtracao');

  return Object.values(totaisPorFinalizadora);
};
