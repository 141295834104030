import { MutableRefObject } from 'react';

const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const buscarElemento = async (
  ref: MutableRefObject<HTMLElement | null> | null,
  idOrName: string,
  click: boolean,
  msDelay?: number,
): Promise<void> => {
  const root = ref?.current ?? document;

  let element = root.querySelector(`#${idOrName}`) as HTMLElement | null;

  if (!element) {
    const elementByName = root.querySelectorAll(`[name="${idOrName}"]`);
    element =
      elementByName.length > 0 ? (elementByName[0] as HTMLElement) : null;
  }

  if (element) {
    await delay(msDelay || 100);

    element.focus();
    if (click) element.click();
  }
};
