export const padString = (
  value: string,
  totalLength: number,
  fillChar: string,
  side: 'left' | 'right',
): string => {
  if (fillChar.length !== 1)
    throw new Error(
      'O caractere de preenchimento deve ter exatamente um caractere.',
    );

  if (side === 'left') return value.padStart(totalLength, fillChar);
  if (side === 'right') return value.padEnd(totalLength, fillChar);
  throw new Error("O lado deve ser 'left' ou 'right'.");
};
